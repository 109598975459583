import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';

import Container from '@material-ui/core/Container';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import {Grid, Button, Link} from '@material-ui/core';
import SelectMultiLang from '../../components/SelectMultiLang/index';

/**
 * Maintain view
 */
class Index extends Component {
  /**
   * Render view
   * @return {object}
   */
  render() {
    const {t} = this.props;
    return (
      <div>
        <Container maxWidth="md" style={{height: '100vh'}}>
          <Grid container spacing={2}>
            <Grid item xs={11} justify="center" alignItems="center" container>
              <br></br>
              <br></br>
            </Grid>
            <Grid item xs={1} justify="flex-end" alignItems="center" container>
              <SelectMultiLang />
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container>
              <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header"></img>
            </Grid>
            <Grid item xs={12} justify="center" alignItems="center" container>
              <h1>Unauthorized</h1>
            </Grid>
          </Grid>
          <br></br>
          <Grid container spacing={6}>
            <Grid item xs={12} justify="center" alignItems="center" container>
              <h3> {t('unauthorized')}</h3>
            </Grid>

            <Grid item container justify="center" alignItems="center" xs={12}>
              <Link href="/maas/home">
                <Button variant="contained" color="primary" endIcon={<KeyboardReturnIcon />}>
                  {t('route.home')}
                </Button>
              </Link>
            </Grid>
          </Grid>
          <br></br>
        </Container>
      </div>
    );
  }
}
export default withTranslation('translations')(Index);
