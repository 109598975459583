module.exports = {
  ROUTE: {
    // Main page
    LAYOUT: '/payment',
    PAYMENT_PRODUCT: '/products',
    PAYMENT_CARD: '/card',
    PAYMENT_CONFIRM: '/confirm',
    PAYMENT_COMPLETE: '/complete',
    HOME: '/home',
    LOGIN: '/login',


    // Error Page UI
    MAINTENANCE: '/maintenance',
    UNAUTHORIZED: '/unauthorized',
  },

  ROUTER_KEY: {
    PAGE_VIEW: 1,
    PAGE_LAYOUT: 2,
  },
  MONTH: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
};
