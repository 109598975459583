import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Container, Grid} from '@material-ui/core';
import SelectMultiLang from '../../components/SelectMultiLang/index';
import './style.css';

/**
 * Home view
 */
class Index extends Component {
  /**
   * Render view
   * @return {object}
   */
  render() {
    return (
      <Container maxWidth="md" style={{height: '100vh'}}>
        <Grid container spacing={2}>
          <Grid item xs={11} justify="center" alignItems="center" container>
            <br></br>
            <br></br>
          </Grid>
          <Grid item xs={1} justify="flex-end" alignItems="center" container>
            <SelectMultiLang />
          </Grid>
          <Grid item xs={12} justify="center" alignItems="center" container>
            <img src={`${process.env.PUBLIC_URL}/logo-title.png`} alt="Logo" className="logo_header"></img>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
