import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router-dom';
import {Container, Dialog} from '@material-ui/core';
import routes from '../router.js';
import {ROUTE} from '../common/constant';
import {withTranslation} from 'react-i18next';
import MessageModal from '../components/messageModal';


/**
 * Main Layout component
 */
class Index extends Component {
  /**
   * get routes
   * @param {routes} r
   * @return {Route} Route
   */
  getRoutes = (r) => {
    return r.map((prop, key) => {
      if (prop.layout === ROUTE.LAYOUT) {
        const currentRoute = this.getCurrentRoute();
        if (!prop.subMenu || !currentRoute) {
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => <prop.component {...props} handleClick={this.handleNotificationClick} />}
              key={key}
            />
          );
        } else {
          return (
            <Route
              path={currentRoute.layout + currentRoute.path}
              render={(props) => <currentRoute.component {...props} handleClick={this.handleNotificationClick} />}
              key={key}
            />
          );
        }
      }
      return null;
    });
  };

  /**
   * getCurrentRoute
   * @return {String}
   */
  getCurrentRoute = () => {
    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];
      if (route?.subMenu) {
        for (let j = 0; j < route.subMenu.length; j++) {
          if (this.props.location.pathname.includes(route.subMenu[j].layout + route.subMenu[j].pathActive)) {
            return route.subMenu[j];
          }
        }
      } else {
        if (this.props.location.pathname.includes(route.layout + route.pathActive)) {
          return route;
        }
      }
    }
    return [];
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    if (!this.props.i18n.languages.includes(localStorage.getItem('i18nextLng'))) {
      this.props.i18n.changeLanguage('jp');
    }
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    if (!this.props.i18n.languages.includes(localStorage.getItem('i18nextLng'))) {
      this.props.i18n.changeLanguage('jp');
    }
  }

  /**
   * Render component
   * @return {component}
   */
  render() {
    const currentRoute = this.getCurrentRoute();
    return (
      <div>
        <Container maxWidth="md" className="p-0">
          <Switch currentRoute={currentRoute}>{this.getRoutes(routes)}</Switch>
        </Container>
        <Dialog
          open={this.props.modal.flgMessage}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: '1020',
          }}
        >
          <MessageModal></MessageModal>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modal: state.modal,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withTranslation('translations')(connect(mapStateToProps, mapDispatchToProps)(Index));
