export const displayDateTime = (stringISO) => {
  return (
    stringISO.substring(0, 4) +
    '年' +
    stringISO.substring(5, 7) +
    '月' +
    stringISO.substring(8, 10) +
    '日 ' +
    stringISO.substring(11, 13) +
    ':' +
    stringISO.substring(14, 16)
  );
};

export const validateInputEmpty = (input) => {
  if (input === null) {
    return true;
  }
  if (typeof input === 'string') {
    if (input === '' || !input.match(/\S/g)) {
      return true;
    }
    return false;
  }
};

export const validateInputLengthCheck = (input) => {
  if (input.length > 255) {
    return true;
  } else {
    return false;
  }
};

export const isValidEmail = (email) => {
  /* eslint-disable */
  const regex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
  return regex.test(String(email).toLowerCase());
};

export const passwordLengthCheck = (password) => {
  if (password.length < 6) {
    return true;
  } else {
    return false;
  }
};

export const checkPhoneNumber = (phoneNumber) => {
  let regex = '^([0|+[0-9]{1,5})?([0-9]{9,16})$';
  return phoneNumber.match(regex);
};

export const redirectRouter = (props, path) => {
  props.history.push(path);
};

/**
 * handel onChange TextField's value in component
 * @param {component} component
 * @param {event} event
 */
export const onChangeTextField = (component, event) => {
  component.setState({
    ...component.state,
    [event.currentTarget.name]: event.target.value,
  });
};

/**
 * handle onChange SelectBox's value in component
 * @param {component} component
 * @param {event} event
 */
export const onChangeSelect = (component, event) => {
  component.setState({
    ...component.state,
    [event.target.name]: event.target.value,
  });
};

/**
 * handle onChange field's value in component state (array)
 * @param {component} component
 * @param {array} data
 * @param {string} fieldName
 * @param {int} index
 * @param {*} value
 */
export const onChangeListData = (component, data, fieldName, index, value) => {
  const copyData = data;
  copyData[index][fieldName] = value;
  component.setState({data: copyData});
};
