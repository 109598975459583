import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import jp from './jp';

i18n.use(LanguageDetector).init({
  // we init with resources
  resources: {
    jp: jp,
    en: en,
  },
  debug: false,
  load: 'current',

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

i18n.languages = ['jp', 'en'];

export default i18n;
