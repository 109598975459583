import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Container,
  Grid,
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
} from '@material-ui/core';
import {redirectRouter} from '../../../utils/common';
import {ROUTE} from '../../../common/constant';

/**
 * PaymentConfirmation
 */
class Confirm extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {productType: 1};
  }
  /**
   * handleChange
   * @param {Object} event
   */
  handleChange = (event) => {
    console.log(event.target.value);
    this.setState({productType: event.target.value});
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div className="Confirm">
        {/* Step 1 */}
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Container maxWidth="xl">
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Table size="small">
                      <TableHead></TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.dateTime')}</TableCell>
                          <TableCell>2019年2月17日 11:2</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.number')}</TableCell>
                          <TableCell>11091992</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.orderer')}</TableCell>
                          <TableCell>イ・ミンホ</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.totalFee')}</TableCell>
                          <TableCell>3900円</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.paymentStatus')}</TableCell>
                          <TableCell>成功</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.registerer')}</TableCell>
                          <TableCell>グエンヴァンナム</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.expiredDate')}</TableCell>
                          <TableCell>02/22</TableCell>
                        </TableRow>
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color right"
                      onClick={this.toComplete}
                    >
                      {t('newReservation.next')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Container>
      </div>
    );
  }

  /**
   * toComplete
   */
  toComplete = () => {
    const path = ROUTE.LAYOUT + ROUTE.PAYMENT_COMPLETE;
    redirectRouter(this.props, path);
  };
}

export default withTranslation('translations')(Confirm);
