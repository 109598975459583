const jp = {
  translations: {
    // Route
    'route.home': 'ホームページ',
    'route.login': 'ログインする',
    'route.registration': '登録',
    'route.logout': 'ログアウト',
    'route.verify_mobile_account': 'モバイルアカウントを確認',

    // Button common
    'common.btnAgree': '同意する',
    'common.btnAdd': '追加',
    'common.btnUpdate': '更新',
    'common.btnDelete': '削除する',
    'common.btnSend': '送信',
    'common.btnChange': '変更',
    'common.btnLogin': 'ログイン',
    'common.btnLogout': 'ログアウト',
    'common.btnRegister': '登録',
    'common.btnCreate': '登録',
    'common.btnReturn': '戻る',
    'common.btnCancel': '閉じる',
    'common.btnResetSearch': '検索条件リセット',
    'common.btnSearch': '検索',
    'common.btnReopen.Stop': '停止／再開',
    'common.btnUploadCsv': 'CSV出カ',
    'common.btnDetail': '細部',
    'common.btnClose': '閉じる',
    'common.btnBulkRegistration': '一括登録',
    // Common message code
    'messageCode.updateConfirm': 'この{{field}}を更新しますか？',
    'messageCode.deleteConfirm': 'この{{field}}を削除しますか？',
    'messageCode.changePasswordConfirm': 'パスワードを更新します。よろしいですか？',
    'messageCode.accountInviteConfirm': 'メールを送信します。よろしいですか？',
    'messageCode.deleteConfirmProduct': '製品を削除しますか？',
    'messageCode.deleteConfirmSupplier': 'サプライヤーを削除しますか?',
    'messageCode.deleteConfirmDriver': 'ドライバーを削除しますか?',
    'messageCode.deleteConfirmImageMaster': 'イメージマスターを削除しますか？',
    'messageCode.updateConfirmDriver': 'ドライバを更新しますか？',
    'messageCode.updateConfirmVehicle': '車両を更新しますか？',
    'messageCode.deleteConfirmVehicle': '車両を削除しますか？',
    'messageCode.verifyEmailToken': 'ご登録いただいたメールアドレスの認証に成功しました',
    // Common error field message
    'errorFields.invalidDateMessage': '無効な日付形式',
    'errorFields.maxDateMessage': '日付は最大日付の後にしないでください',
    'errorFields.minDateMessage': '日付は最小日付より前であってはなりません',
    'errorFields.passwordRequired': 'パスワードを入力してください。',
    'errorFields.emailRequired': 'メールを入力してください。',
    'errorFields.emailFormat': '入力されたメールの形式が正しくありません',
    'errorFields.textLength': '128文字以内で入力してください',
    'errorFields.text256Length': '256文字以内で入力してください',
    'errorFields.cannotChange': '変更不可',
    'errorFields.birthdayRequired': '誕生日を入力して下さい。',
    'errorFields.accountNameRequired': 'アカウント名を入力してください。',
    'errorFields.rePasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.phoneNumberRequired': '電話番号を入力してください。',
    'errorFields.confirmPasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.oldPasswordRequired': '現在のパスワードを入力してください。',
    'errorFields.newPasswordRequired': '新パスワードを入力してください。',
    'errorFields.passwordMin': '小文字を含む6文字以上で入力してください。',
    'errorFields.confirmPasswordRequired': '再入力パスワードを入力してください。',
    'errorFields.personChargeRequired': '担当者名を入力してください。',
    'errorFields.firstNameRequired': '名を入力してください。',
    'errorFields.lastNameRequired': '姓を入力してください。',
    'errorFields.addressRequired': '住所を入力してください。',
    'errorFields.rePasswordMatch': '新パスワードと再入力パスワードが一致しません。もう一度入力してください。',
    'errorFields.businessRequired': '会社名を入力してください。',
    'errorFields.mobileRequired': '電話番号を入力してください',
    'errorFields.checkPhoneNumberMatch': '電話番号が一致しません。もう一度やり直してください。',
    'errorFields.sizeFile': '画像サイズは200KBより大きく、500KBを超えてはなりません。',
    'errorFields.statusRequired': 'ステータスを選択してください。',
    'errorFields.typeRequired': 'タイプを選択してください。',
    'errorFields.industryRequired': '業種をお選びください。',
    'errorFields.countryRequired': '国を選択してください。',
    'errorFields.supplierNameRequired': 'サプライヤー名を選択してください。',
    'errorFields.supplierCodeRequired': 'サプライヤーコードを選択してください。',
    'errorFields.supplierAddressRequired': '住所を選択してください。',
    'errorFields.facilityNameRequired': '施設名を入力してください。',
    'errorFields.areaRequired': 'エリアをお選びください。',
    'errorFields.languageRequired': '言語を選択してください。',
    'errorFields.holidayRequired': '休日をお選びください。',
    'errorFields.feeRequired': '料金を入力してください。',
    'errorFields.feeTypeRequired': 'タイプ料をお選びください',
    'errorFields.errorPrice': '料金を入力してください',
    // Message error call API
    'error.file.size.less.than.200KB': '200kb以上のファイルサイズ',
    'error.file.size.is.empty': 'ファイルを空白のままにすることはできません',
    'error.no.record': '記録なし',
    'error.already.exist.or.has.been.invited': 'メールはすでに存在するか、招待されています。',
    'error.account.user.not.found': 'アカウントユーザーが見つかりません。',
    'error.business.not.found': 'ビジネスが見つかりません。',
    'error.role.not.found': '役割が見つかりません。',
    'error.permission.not.found': '権限が見つかりません。',
    'error.api.role.permission.unknown': 'API役割の権限は不明です。',
    'error.api.token.not.match': 'APIトークンが一致しません。',
    'error.link.not.found': 'リンクが見つかりません。',
    'error.user.not.resumed': 'アカウントユーザーは再開されていません。',
    'error.link.expired': 'リンクの有効期限が切れています。',
    'error.email.not.found': 'メールが見つかりません。',
    'error.email.password.invalid': 'パスワードが無効です。',
    'error.change.password': 'このパスワードは間違っています。',
    'error.newPassword.must.not.equals.currentPassword': '現在のパスワードと新しいパスワードは一致してはなりません。',
    'error.not.found.user': 'ユーザーが見つかりません。',
    'error.user.not.exists': 'ユーザーが存在しません',
    'error.email.exists': 'メールが存在しません',
    'error.mobile.exists': '携帯は存在しません',
    'error.reissue.password.token_expired': 'トークンはすでに存在します',
    'error.reissue.password.token_not_match': 'トークンの形式が正しくありません',
    'error.member.not.exists': '',
    'invalid.email': '無効なメール',
    'invalid.timeTo': '無効な時間',
    'invalid.timeFrom': 'からの無効な時間',
    'error.time.from.not.after.time.to': 'からはあでもっと少なく',
    'user.email.token.invalid': 'メールトーケン認証が失敗しました',
    'error.check.value.empty': '入力する値を確認してください',
    // Message success call API
    'api.invite.success': 'アカウントを招待しました。',
    'api.active.account.success': 'アクティブなアカウントが正常に作成されました。',
    'api.add.supplier.success': 'サプライヤーを追加しました。',
    'api.deleted.supplier.success': 'サプライヤーを削除しました',
    'api.delete.driver.success': 'ドライバーを削除しました',
    'api.delete.caution.success': 'アイテムーを削除しました',
    'api.update.caution.success': 'アイテムをーを更新しました',
    'api.create.driver.success': 'ドライバーを作成しました',
    'api.create.caution.success': 'アイテムーを作成しました',
    'api.updated.supplier.success': 'サプライヤーを更新しました。',
    'api.updated.account.success': 'アカウントを更新しました。',
    'api.execute.reissue.success': 'パスワードの再発行に成功しました。',
    'Api.success.change.password': 'パスワードを変更しました。',
    'api.updated.member.success': 'メンバーを更新しました。',
    'api.created.member.success': 'メンバーを作成しました',
    'api.reissue_password.request.success': 'パスワードの再発行をリクエストしました',
    'api.reissue_password.verify.success': '再発行パスワードを確認しました',
    'api.reissue_password.confirm.success': '再発行パスワードを確認しました',
    'api.canceled.member.success': 'メンバーはキャンセルされました',
    'api.deleted.facility.success': '施設を削除しました',
    'api.updated.facility.success': '施設を更新しました',
    'api.add.facility.success': '施設を追加しました',
    'api.facility.not.found': 'API機能が見つかりません',
    'api.delete.vehicle.success': '車両を削除しました',
    'api.updated.vehicle.success': 'サプライヤーの車両を更新しました',
    'api.created.vehicle.success': 'サプライヤーの車両を作成しました',
    'api.update.calendar.success': 'カレンダーを更新しました',
    'api.create.image.success': '画像を作成しました',
    'api.update.image.success': '画像を更新しました',
    'api.delete.image.success': '画像を削除しました',
    'user.verify.email.token.success': '認証に成功しました',
    // Login page
    'login.email': 'メール',
    'login.password': 'パスワード',
    'login.passwordReissue': 'パスワード再発行の方はこちら',
    // Home page
    'homePage.title': 'ホーム',
    // Registration page
    'registration.title': 'アカウント作成',
    'registration.accountType': 'アカウント種別',
    'registration.authority': '権限',
    'registration.businessName': '事業者名',
    'registration.email': 'メール',
    'registration.contactName': '担当者名',
    'registration.password': 'パスワード',
    'registration.rePassword': '再入力パスワード',
    'registration.phoneNumber': '電話番号',
    // Error page UI
    'maintenance': 'ただいまメンテナンス中',
    'unauthorized': 'しまった !!! このリソースへのアクセスは拒否されました。',

    // validation
    'validation.required': '{{ field }}入力してください。',
    'validation.email': '入力されたメールの形式が正しくありません',
    'validation.required.choose': '{{ field }}を選択してください',
    'validation.numeric': '{{ field }}は数字でなければなりません',
    // new Reservation
    'newReservation.dateTime': '予約日時',
    'newReservation.number': '予約番号',
    'newReservation.orderer': '注文者',
    'newReservation.totalFee': '金額',
    'newReservation.paymentStatus': '入金状況',
    'newReservation.registerer': '予約登録者',
    'newReservation.cardNumber': 'カード番号',
    'newReservation.cardCompany': 'カード会社',
    'newReservation.expiredDate': '有効期限',
    'newReservation.month': '月',
    'newReservation.year': '年',
    'newReservation.back': '戻る',
    'newReservation.next': '次のページ',
    'newReservation.confirm': '確認',
    'newReservation.complete': '決済の登録が完了しました',
    'newReservation.operator': 'オペレータ',
  },
};

export default jp;
