import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Container,
  Grid,
  Box,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableFooter,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  TextField,
} from '@material-ui/core';
import 'react-tabs/style/react-tabs.css';
import {MONTH, ROUTE} from '../../../common/constant';
import {onChangeSelect, redirectRouter} from '../../../utils/common';
/**
 * CardInfo
 */
class CardInfo extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {productType: 1};
  }
  /**
   * handleChange
   * @param {Object} event
   */
  handleChange = (event) => {
    this.setState({productType: event.target.value});
  };

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div className="CardInfo">
        {/* Step 1 */}
        <Container maxWidth="xl">
          <Box p={1} m={1}>
            <Container maxWidth="xl">
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Table size="small">
                      <TableHead></TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.dateTime')}</TableCell>
                          <TableCell className="">2019年2月17日 11:20</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.number')}</TableCell>
                          <TableCell className="">1091992</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.orderer')}</TableCell>
                          <TableCell className="">イ・ミンホ</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.totalFee')}</TableCell>
                          <TableCell className="">3900円</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className=" width_30">{t('newReservation.paymentStatus')}</TableCell>
                          <TableCell className="">成功</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className=" width_30">{t('newReservation.registerer')}</TableCell>
                          <TableCell className="">グエンヴァンナム</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className=" width_30">{t('newReservation.cardNumber')}</TableCell>
                          <TableCell className="">
                            <TextField
                              className="field_size_10 field_min_size_150"
                              margin="dense"
                              variant="outlined"
                              value={1111}
                            />
                            -
                            <TextField
                              className="field_size_10 field_min_size_150"
                              margin="dense"
                              variant="outlined"
                              value={2222}
                            />
                            -
                            <TextField
                              className="field_size_10 field_min_size_150"
                              margin="dense"
                              variant="outlined"
                              value={3333}
                            />
                            -
                            <TextField
                              className="field_size_10 field_min_size_150"
                              margin="dense"
                              variant="outlined"
                              value={4444}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.cardCompany')}</TableCell>
                          <TableCell>
                            <FormControl variant="outlined" className="field_size_10 field_min_size_100" margin="dense">
                              <Select margin="dense" labelWidth={60} value="1">
                                <MenuItem value="1">Visa</MenuItem>
                                <MenuItem value="2">Master</MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="width_30">{t('newReservation.expiredDate')}</TableCell>
                          <TableCell>
                            <FormControl variant="outlined" className="field_size_10 field_min_size_100" margin="dense">
                              <InputLabel>---</InputLabel>
                              <Select margin="dense" labelWidth={60} onChange={(event) => onChangeSelect(this, event)}>
                                {MONTH.map((item) => {
                                  return (
                                    <MenuItem value={item} key={item}>
                                      {t(`${item}`)}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                            <span>{t('newReservation.month')}</span>
                            <FormControl variant="outlined" className="field_size_10 field_min_size_100" margin="dense">
                              <InputLabel>----</InputLabel>
                              <Select margin="dense" labelWidth={60} onChange={(event) => onChangeSelect(this, event)}>
                                <MenuItem value="1">2020</MenuItem>
                                <MenuItem value="2">2021</MenuItem>
                                <MenuItem value="2">2022</MenuItem>
                              </Select>
                            </FormControl>
                            <span>{t('newReservation.year')}</span>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      <TableFooter></TableFooter>
                    </Table>
                  </Grid>
                </Grid>
              </Box>
              <Box p={2} m={2}>
                <Grid container spacing={3} alignItems="flex-start">
                  <Grid item xs={12}>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color"
                      onClick={this.props.history.goBack}
                    >
                      {t('common.btnReturn')}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      className="button_magin button_color right"
                      onClick={this.toConfirm}
                    >
                      {t('newReservation.next')}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Container>
          </Box>
        </Container>
      </div>
    );
  }

  /**
   * toConfirm
   */
  toConfirm = () => {
    const path = ROUTE.LAYOUT + ROUTE.PAYMENT_CONFIRM;
    redirectRouter(this.props, path);
  };
}

export default withTranslation('translations')(CardInfo);
