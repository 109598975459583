const en = {
  translations: {
    // Route
    'route.home': 'Home',
    'route.login': 'Login',
    'route.registration': 'Registration',
    'route.logout': 'Logout',
    'route.verify_mobile_account': 'Verify Mobile Account',

    // Button common
    'common.btnAgree': 'Agree',
    'common.btnAdd': 'Add',
    'common.btnUpdate': 'Update',
    'common.btnDelete': 'Delete',
    'common.btnSend': 'Send',
    'common.btnChange': 'Change',
    'common.btnLogin': 'login',
    'common.btnLogout': 'Logout',
    'common.btnRegister': 'Register',
    'common.btnCreate': 'Create',
    'common.btnReturn': 'Return',
    'common.btnCancel': 'Cancel',
    'common.btnResetSearch': 'Search condition reset',
    'common.btnSearch': 'Search',
    'common.btnReopen.Stop': 'Re-Open/Stop',
    'common.btnUploadCsv': 'CSV Output',
    'common.btnDetail': 'Detail',
    'common.btnClose': 'Close',
    'common.btnBulkRegistration': 'Collective Register',
    // Common message code
    'messageCode.updateConfirm': 'Do you want to update this {{field}}?',
    'messageCode.deleteConfirm': 'Do you want to delete this {{field}}?',
    'messageCode.changePasswordConfirm': 'Update your password. Is it OK?',
    'messageCode.accountInviteConfirm': 'Send an email. Is it OK?',
    'messageCode.deleteConfirmProduct': 'Do you want to delete the product?',
    'messageCode.deleteConfirmSupplier': 'Do you want to delete the supplier?',
    'messageCode.deleteConfirmDriver': 'Do you want to delete the driver?',
    'messageCode.deleteConfirmImageMaster': 'Do you want to delete the image master?',
    'messageCode.updateConfirmDriver': 'Do you want to update the driver?',
    'messageCode.updateConfirmVehicle': 'Do you want to update the vehicle?',
    'messageCode.deleteConfirmVehicle': 'Do you want to delete the vehicle?',
    'messageCode.verifyEmailToken': 'The email address you registered has been successfully authenticated.',
    // Common error field message
    'errorFields.invalidDateMessage': 'Invalid Date Format',
    'errorFields.maxDateMessage': 'Date should not be after maximal date',
    'errorFields.minDateMessage': 'Date should not be before minimal date',
    'errorFields.passwordRequired': 'Please enter the Password.',
    'errorFields.emailRequired': 'Please enter the Email.',
    'errorFields.emailFormat': 'Invalid email address format.',
    'errorFields.textLength': 'Please enter within 128 characters',
    'errorFields.text256Length': 'Please enter within 256 characters',
    'errorFields.cannotChange': 'Can not change',
    'errorFields.birthdayRequired': 'Please enter your Birthday.',
    'errorFields.accountNameRequired': 'Please enter your Account Name.',
    'errorFields.rePasswordRequired': 'Please enter the Re-enter Password.',
    'errorFields.phoneNumberRequired': 'Please enter your Phone Number.',
    'errorFields.confirmPasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.oldPasswordRequired': 'Please enter your current password.',
    'errorFields.newPasswordRequired': 'Please enter a new password.',
    'errorFields.passwordMin': 'Please enter at least 6 characters including lowercase letters.',
    'errorFields.confirmPasswordRequired': 'Please enter the password again.',
    'errorFields.personChargeRequired': 'Please enter the Person in charge.',
    'errorFields.firstNameRequired': 'Please enter the First Name.',
    'errorFields.lastNameRequired': 'Please enter the Last Name.',
    'errorFields.addressRequired': 'Please enter the Address.',
    'errorFields.rePasswordMatch': 'The new password and re-enter password do not match. Please try again.',
    'errorFields.businessRequired': 'Please enter the Business name.',
    'errorFields.mobileRequired': 'Please enter the phone number.',
    'errorFields.checkPhoneNumberMatch': 'The phone number do not match. Please try again.',
    'errorFields.sizeFile': 'Image size must be greater than 200KB and not exceed 500KB.',
    'errorFields.statusRequired': 'Please choose the status.',
    'errorFields.typeRequired': 'Please choose the type.',
    'errorFields.industryRequired': 'Please choose the industry.',
    'errorFields.countryRequired': 'Please choose the country.',
    'errorFields.supplierNameRequired': 'Please choose the supplier name.',
    'errorFields.supplierCodeRequired': 'Please choose the supplier code.',
    'errorFields.supplierAddressRequired': 'Please choose the address.',
    'errorFields.facilityNameRequired': 'Please enter the facility name.',
    'errorFields.areaRequired': 'Please choose the area.',
    'errorFields.languageRequired': 'Please choose the language.',
    'errorFields.holidayRequired': 'Please choose the holiday.',
    'errorFields.feeRequired': 'Please enter the fee.',
    'errorFields.feeTypeRequired': 'Please choose the type fee.',
    'errorFields.errorPrice': 'Please enter the charges.',
    // Message error call API
    'error.file.size.less.than.200KB': 'File size not less than 200kb',
    'error.file.size.is.empty': 'The file cannot be left blank',
    'error.no.record': 'No records',
    'error.already.exist.or.has.been.invited': 'Email already exist or has been invited.',
    'error.account.user.not.found': 'Account user not found.',
    'error.business.not.found': 'Business not found.',
    'error.role.not.found': 'Role not found.',
    'error.permission.not.found': 'Permission not found.',
    'error.api.role.permission.unknown': 'Api role-permission unknown.',
    'error.api.token.not.match': 'Api token not match.',
    'error.link.not.found': 'Link not found.',
    'error.user.not.resumed': 'Account user not resumed.',
    'error.link.expired': 'Link expired.',
    'error.email.not.found': 'Email not found.',
    'error.email.password.invalid': 'Password invalid.',
    'error.change.password': 'Current password is incorrect.',
    'error.newPassword.must.not.equals.currentPassword': 'The current password and new password must not match.',
    'error.not.found.user': 'User not found.',
    'error.user.not.exists': 'User does not exist',
    'error.email.exists': 'Mail does not exist',
    'error.mobile.exists': 'Mobile does not exist',
    'error.reissue.password.token_expired': 'Token already exists',
    'error.reissue.password.token_not_match': 'Bad token format',
    'error.member.not.exists': 'Member does not exist',
    'invalid.email': 'Invalid email',
    'invalid.timeTo': 'Invalid time to',
    'invalid.timeFrom': 'Invalid time from',
    'error.time.from.not.after.time.to': 'The start time is less than the end time',
    'user.email.token.invalid': 'Verify email token failure.',
    'error.check.value.empty': 'Please check the values ​​to be entered',
    // Message success call API
    'api.invite.success': 'Invited accounts successfully.',
    'api.active.account.success': 'Active account successfully.',
    'api.add.supplier.success': 'Add supplier successfully.',
    'api.deleted.supplier.success': 'Delete supplier successfully',
    'api.delete.driver.success': 'Delete driver successfully',
    'api.delete.caution.success': 'Delete item successfully',
    'api.update.driver.success': 'Update driver successfully',
    'api.update.caution.success': 'Update item successfully',
    'api.create.driver.success': 'Create driver successfully',
    'api.updated.supplier.success': 'Updated supplier successfully.',
    'api.updated.account.success': 'Updated account successfully.',
    'api.execute.reissue.success': 'Password reissue successfully.',
    'Api.success.change.password': 'Change password successfully.',
    'api.updated.member.success': 'Updated member successfully.',
    'api.created.member.success': 'Created member successfully',
    'api.reissue_password.request.success': 'Request reissue password successfully',
    'api.reissue_password.verify.success': 'Verify reissue password successfully',
    'api.reissue_password.confirm.success': 'Confirm reissue password successfully',
    'api.canceled.member.success': 'Cancelled member successfully',
    'api.deleted.facility.success': 'Delete facilities successfully',
    'api.updated.facility.success': 'Updated facility successfully.',
    'api.add.facility.success': 'Add facility successfully.',
    'api.facility.not.found': 'API facility not found',
    'api.delete.vehicle.success': 'Delete supplier\'s vehicle successfully.',
    'api.updated.vehicle.success': 'Updated supplier\'s vehicle successfully',
    'api.created.vehicle.success': 'Created supplier\'s vehicle successfully',
    'api.update.calendar.success': 'Updated calendar successfully.',
    'api.create.image.success': 'Create image successfully',
    'api.update.image.success': 'Update image successfully',
    'api.delete.image.success': 'Delete image successfully',
    'user.verify.email.token.success': 'Verify email token successfully.',
    // Login page
    'login.email': 'Email',
    'login.password': 'Password',
    'login.passwordReissue': 'Click here for password reissue',
    // Home page
    'homePage.title': 'Home',
    // Registration page
    'registration.title': 'Account creation',
    'registration.accountType': 'Account type',
    'registration.authority': 'Authority',
    'registration.businessName': 'Business name',
    'registration.email': 'Email',
    'registration.contactName': 'Contact name',
    'registration.password': 'Password',
    'registration.rePassword': 'Re-enter password',
    'registration.phoneNumber': 'Phone number',
    // Error page UI
    'maintenance': 'We are currently down for maintenance.',
    'unauthorized': 'Whoops!!!  Access to this resource is denied',

    // validation
    'validation.required': 'Please enter the {{ field }}',
    'validation.email': 'Invalid email address format.',
    'validation.required.choose': 'Please choose the {{ field }}',
    'validation.numeric': '{{ field }} must be a number',

    // new Reservation
    'newReservation.dateTime': 'Reservation date time',
    'newReservation.number': 'Reservation number',
    'newReservation.orderer': 'Orderer',
    'newReservation.totalFee': 'Total fee',
    'newReservation.paymentStatus': 'Payment status',
    'newReservation.registerer': 'Reservation registerer',
    'newReservation.cardNumber': 'Card number',
    'newReservation.cardCompany': 'Card company',
    'newReservation.expiredDate': 'Expired date',
    'newReservation.month': 'Month',
    'newReservation.year': 'Year',
    'newReservation.back': 'Back',
    'newReservation.next': 'Next',
    'newReservation.confirm': 'Confirm',
    'newReservation.complete': 'Payment registration is complete',
    'newReservation.operator': 'Operator',
  },
};

export default en;
