import {combineReducers} from 'redux';
import authen from '../stores/authen/reducer';
import modal from '../stores/modal/reducer';

const reducer = combineReducers(
    Object.assign({
      authen,
      modal,
    }),
);

export default reducer;
