import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {
  Container,
  Grid,
  Button,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Typography,
  IconButton,
} from '@material-ui/core';
import ChevronRight from '@material-ui/icons/ChevronRight';
import LockOutlined from '@material-ui/icons/LockOutlined';
import {redirectRouter} from '../../../utils/common';
import {ROUTE} from '../../../common/constant';
import './styles.css';
/**
 * PaymentProducts
 */
class PaymentProducts extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;
    return (
      <div className="PaymentProducts">
        <Container maxWidth="xl">
          <Grid container alignItems="center" justify="space-between" item>
            <Button
              variant="contained"
              className="button_color"
              color="primary"
              onClick={() => {}}
              endIcon={<LockOutlined />}
            >
              {t('common.btnLogout')}
            </Button>
            <Typography gutterBottom variant="h4" component="h4" className="text_center p-15">
              300 WILLER 株式会社
            </Typography>
            <IconButton className="right-button" size="small">
              <ChevronRight fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid container className="table-wrapper" alignItems="center" item xs={12}>
            <Table size="small" aria-label="sticky table" stickyHeader className="layoutfix">
              <TableBody>
                <TableRow className="product-row">
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.number')} : 11091992
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.totalFee')}: 3900円
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.orderer')}: イ・ミンホ
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.dateTime')}: 2019年2月17日 11:20
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton className="right-button" size="small" onClick={this.toCard}>
                      <ChevronRight fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
                <TableRow className="product-row" onClick={this.toCard}>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.number')} : 09112981
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.totalFee')}: 8900円
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.orderer')}: ホーチミン
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="h6" component="h6" className="text_center">
                      {t('newReservation.dateTime')}: 2018年4月12日 07:20
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <IconButton className="right-button" size="small" onClick={this.toCard}>
                      <ChevronRight fontSize="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Container>
      </div>
    );
  }

  /**
   * toCard
   */
  toCard = () => {
    const path = ROUTE.LAYOUT + ROUTE.PAYMENT_CARD;
    redirectRouter(this.props, path);
  };
}

export default withTranslation('translations')(PaymentProducts);
