import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Container, Grid, Typography} from '@material-ui/core';
import './styles.css';
/**
 * Payment complete view
 */
class Complete extends Component {
  /**
   * Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
  }

  /**
   * render component
   * @return {component}
   */
  render() {
    const {t} = this.props;

    return (
      <div>
        <Container maxWidth="xl">
          <Grid container spacing={1} className="page_header">
            <Grid container alignItems="center" item xs={12} className="margin_20 mt-20 mb-20">
              <span className="border_complete">{t('newReservation.complete')}</span>
            </Grid>
            <Grid container alignItems="center" item justify="center">
              <Typography gutterBottom variant="h4" component="h4" className="text_center mt-20 mb-20">
                300 WILLER 株式会社
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withTranslation('translations')(Complete);
