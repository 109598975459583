import {ACTION_TYPES} from './reducer';
import {setMessageModal} from '../modal/actions';
import {modalObj} from '../../utils/modal.js';
import {loginApi} from '../../services/authenServices';
import {ROUTE} from '../../common/constant';

// login
const requestLoginAction = {
  type: ACTION_TYPES.LOGIN_START,
};

export const successLoginAction = (data) => {
  return {
    type: ACTION_TYPES.LOGIN_SUCCESS,
    objectUser: data,
  };
};

export const failLoginAction = (data) => {
  return {
    type: ACTION_TYPES.LOGIN_FAIL,
  };
};

/**
 * Login
 * @param {string} email
 * @param {string} password
 * @param {object} props
 * @return {object}
 */
export const login = (email, password, props) => {
  return (dispatch) => {
    dispatch(requestLoginAction);
    const body = {
      email: email,
      password: password,
    };
    loginApi(body).then(
        (response) => {
          if (response && response.status === 200) {
            localStorage.setItem('TOKEN_KEY', response.result.token_type + ' ' + response.result.access_token);
            localStorage.setItem('USER_NAME', response.result.person_charge);
            localStorage.setItem('USER_ROLE', response.result.roles);
            localStorage.setItem('refresh_token', response.result.refresh_token);
            dispatch(successLoginAction(response.result));
            props.history.push(ROUTE.LAYOUT + ROUTE.PAYMENT_PRODUCT);
          } else {
            dispatch(failLoginAction(response.message));
            dispatch(setMessageModal(modalObj(true, props.t(response.message_code))));
          }
        },
        (error) => {
          dispatch(failLoginAction('Internal Server Error'));
        },
    );
  };
};
