const axios = require('axios');
const LOGGER = require('../utils/logger');

const validateStatus = (status) => {
  return status === 200 || status === 400 || status === 401 || status === 500;
};

// common base instance
const BASE_URL = process.env.REACT_APP_HOST + 'api/';
const HEADERS_MULTIPLE_PART = {
  'Content-Type': 'multipart/form-data; boundary=something',
};
const HEADER_TOKEN = {
  token: localStorage.getItem('token'),
};

const instance = axios.create({
  'baseURL': BASE_URL,
  'contentType': 'application/json',
  'accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
});

// Add a request interceptor
instance.interceptors.request.use(
    function(config) {
      if (localStorage.getItem('TOKEN_KEY')) {
        config.headers['Authorization'] = localStorage.getItem('TOKEN_KEY');
        config.headers['x-auth-token'] = localStorage.getItem('TOKEN_KEY');
      }
      // Do something before request is sent
      LOGGER.log('******Header=', JSON.parse(JSON.stringify(config)));
      return config;
    },
    function(error) {
    // Các trường hợp lỗi 5xx, 4xx, network xử lý ở đây
      console.log(error);
      // Do something with request error
      return Promise.reject(error);
    },
);

// Add a response interceptor
instance.interceptors.response.use(
    function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

      return response;
    },
    function(error) {
    // Các trường hợp lỗi 5xx, 4xx, network xử lý ở đây
      console.log('response err: ', error);
      if (error.status === 500) {
      // server error
      } else if (error.status === 400) {
      // bad request
      } else if (error.status === 404) {
      // not found
      } else if (error.status === 401) {
      // unauthorize
        console.log(instance);
      } else {
      // something went wrong
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
);

const api = {
  post: (endpoint, params) => {
    LOGGER.log('******Post=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .post(endpoint, params, {
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  postMultiplePart: (endpoint, params) => {
    LOGGER.log('******postMultiplePart=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .post(endpoint, params, {
          headers: HEADERS_MULTIPLE_PART,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  get: (endpoint, params = {}) => {
    LOGGER.log('******Get=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .get(endpoint, {
          params: params,
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  put: (endpoint, params) => {
    LOGGER.log('******Put=', JSON.parse(JSON.stringify(endpoint)));
    LOGGER.log('******Params=', JSON.parse(JSON.stringify(params)));
    return instance
        .put(endpoint, params, {
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },

  delete: (endpoint, params) => {
    LOGGER.log('******Delete=', JSON.parse(JSON.stringify(endpoint)));
    return instance
        .delete(endpoint, {
          data: params,
          headers: HEADER_TOKEN,
          validateStatus: (status) => validateStatus(status),
        })
        .then(
            (response) => {
              LOGGER.log('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        )
        .catch(
            (response) => {
              LOGGER.error('******response', JSON.parse(JSON.stringify(response)));
              return response.data;
            },
            (err) => {
              LOGGER.error('******err', JSON.parse(JSON.stringify(err)));
              return err.response || err;
            },
        );
  },
};

export {api};
