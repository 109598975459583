import {ROUTE, ROUTER_KEY} from './common/constant';
import HomePage from './views/home';
import Login from './views/login';
import PaymentProducts from './views/payment/products';
import CardInfo from './views/payment/card';
import Confirm from './views/payment/confirm';
import Complete from './views/payment/complete';
import Unauthorized from './views/unauthorized';
import maintenance from './views/maintenance';

const dashboardRouters = [
  // Page views (without header, sidebar, footer)
  {
    path: ROUTE.LOGIN,
    pathActive: ROUTE.LOGIN,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.login',
    component: Login,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.UNAUTHORIZED,
    pathActive: ROUTE.UNAUTHORIZED,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.unauthorized',
    component: Unauthorized,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.MAINTENANCE,
    pathActive: ROUTE.MAINTENANCE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.maintenance',
    component: maintenance,
    layout: ROUTE.LAYOUT,
  },


  // Page view inside layout (with header, sidebar, footer)
  {
    path: ROUTE.HOME,
    pathActive: ROUTE.HOME,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.home',
    component: HomePage,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PAYMENT_PRODUCT,
    pathActive: ROUTE.PAYMENT_PRODUCT,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.payment_products',
    component: PaymentProducts,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PAYMENT_CARD,
    pathActive: ROUTE.PAYMENT_CARD,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.payment_card',
    component: CardInfo,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PAYMENT_CONFIRM,
    pathActive: ROUTE.PAYMENT_CONFIRM,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.payment_confirm',
    component: Confirm,
    layout: ROUTE.LAYOUT,
  },
  {
    path: ROUTE.PAYMENT_COMPLETE,
    pathActive: ROUTE.PAYMENT_COMPLETE,
    key: ROUTER_KEY.PAGE_VIEW,
    name: 'route.payment_complete',
    component: Complete,
    layout: ROUTE.LAYOUT,
  },
];

export default dashboardRouters;
