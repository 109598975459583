import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import {persistStore, persistReducer} from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/es/storage';
import reducers from './reducer';
import Config from '../common/config';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [], // whitelist redux
  blacklist: ['authen', 'modal'],
  // blacklist redux
};
const rootReduces = persistReducer(persistConfig, reducers);

const configureStore = () => {
  if (Config.DEBUG) {
    return {
      ...createStore(rootReduces, applyMiddleware(thunk, logger)),
    };
  }
  return {
    ...createStore(rootReduces, applyMiddleware(thunk)),
  };
};

const store = configureStore();
export const persistor = persistStore(store);
export default store;
